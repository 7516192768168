import { UseQueryOptions } from "@tanstack/react-query";

import { getAllScreeners, getScreenerById } from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { AllScreenersResponse, FullScreener } from "@/types/screeners";

const SCREENER_QUERY_KEY_BASE = "screener";
const SCREENER_QUERY_KEYS = {
  base: [SCREENER_QUERY_KEY_BASE] as const,
  screenerId: (screenerId: string) =>
    [...SCREENER_QUERY_KEYS.base, screenerId] as const,
};

export const useAllScreenersQuery = (
  config: Partial<UseQueryOptions<AllScreenersResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_QUERY_KEYS.base,
    queryFn: getAllScreeners,
    ...config,
  });
};

export const useScreenerByIdQuery = (
  screenerId: string,
  config: Partial<UseQueryOptions<FullScreener>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_QUERY_KEYS.screenerId(screenerId),
    queryFn: () => getScreenerById(screenerId),
    ...config,
  });
};
