import { Pagination } from "@mantine/core";
import { flexRender } from "@tanstack/react-table";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import {
  CheckboxFilter,
  convertStringArrayToCheckboxOption,
  DateFilter,
  DateRangeFilter,
  FilterGroup,
  FilterMenu,
} from "@/components/filters";
import { Header } from "@/components/header";
import { ImpersonationBanner } from "@/components/impersonationBanner";
import { TableHeader } from "@/components/table/tableHeader";
import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useAllScreenersQuery } from "@/hooks/queries/screener.queries";
import { useTableHelper } from "@/hooks/use-table-helper.hook";
import { useUserLocalStorageState } from "@/hooks/use-user-local-storage-state.hook";
import { DateRangeOptions } from "@/types/date-range-filter";
import { type Screener, ScreenerLocationStatus } from "@/types/screeners";
import {
  columnSizingHandler,
  formatScreenerColumns,
  getScreenerPinningStyles,
} from "@/utils/columns";
import { screenerHasCompletedSessions } from "@/utils/helpers";
import {
  ALL_SCREENER_PREFIX,
  SEARCH_LOCAL_STORAGE_BASE_KEY,
} from "@/utils/local-storage";

export const ScreenersTable = () => {
  const router = useRouter();
  const track = useTrackAnalytics();
  const { user } = useUserContext();
  const {
    data: tableDataResponse,
    isLoading,
    isPending,
  } = useAllScreenersQuery();
  const { columns, tableData } = useMemo(
    () => ({
      columns: formatScreenerColumns(tableDataResponse?.columns ?? []),
      tableData: tableDataResponse?.screenerRows ?? [],
    }),
    [tableDataResponse],
  );
  const [search, setSearch, searchInitialized] =
    useUserLocalStorageState<string>(
      `${ALL_SCREENER_PREFIX}${SEARCH_LOCAL_STORAGE_BASE_KEY}`,
      "",
    );

  const handleSearch = useCallback(
    (searchValue: string, originalData: Screener[]) => {
      const includesValue = (property?: string) =>
        property?.toLowerCase().includes(searchValue.toLowerCase());

      return originalData.filter(
        ({ internalName, jobTitle }) =>
          includesValue(internalName) || includesValue(jobTitle),
      );
    },
    [],
  );

  const {
    clearAllFilters,
    dateRange,
    filters,
    lastSubmissionDateRange,
    loadingFallback,
    pagination,
    setDateRange,
    setFilters,
    setLastSubmissionDateRange,
    setPagination,
    table,
  } = useTableHelper<Screener>({
    columns,
    columnPinning: {
      left: ["jobTitle"],
      right: [],
    },
    defaultSortingColumn: "lastSubmissionDate",
    handleSearch,
    isLoading: isLoading || isPending,
    localStoragePrefix: ALL_SCREENER_PREFIX,
    search: searchInitialized ? search : "",
    tableData,
  });

  const rows = table.getFilteredRowModel().rows;

  return (
    loadingFallback || (
      <div className="w-full h-dvh">
        <Header />
        <div>
          <TableHeader breadCrumbs={[{ text: "All Screeners" }]} />
          <div className="flex w-full">
            <FilterMenu
              clearAllFilters={() => {
                setSearch("");
                clearAllFilters();
              }}
              onSearch={setSearch}
              filters={filters}
              searchValue={search}
              rowCount={rows.length}
              searchCountName="screeners"
            >
              <FilterGroup title="Status">
                <CheckboxFilter
                  analyticsName={"Session status"}
                  key={"status"}
                  name={"status"}
                  options={convertStringArrayToCheckboxOption([
                    ScreenerLocationStatus.Live,
                    ScreenerLocationStatus.Mixed,
                    ScreenerLocationStatus.Paused,
                  ])}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterGroup>
              <FilterGroup title="Last submission date">
                <DateRangeFilter
                  dateRange={lastSubmissionDateRange}
                  setDateRange={setLastSubmissionDateRange}
                />
                {lastSubmissionDateRange === DateRangeOptions.CustomRange && (
                  <>
                    <DateFilter
                      columnName="lastSubmissionDate"
                      filterName="after"
                      filters={filters}
                      name="Submitted After"
                      setFilters={setFilters}
                    />
                    <DateFilter
                      columnName="lastSubmissionDate"
                      filterName="before"
                      filters={filters}
                      name="Submitted Before"
                      setFilters={setFilters}
                    />
                  </>
                )}
              </FilterGroup>
              <FilterGroup title="Date created">
                <DateRangeFilter
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
                {dateRange === DateRangeOptions.CustomRange && (
                  <>
                    <DateFilter
                      name="Created After"
                      filterName="after"
                      filters={filters}
                      setFilters={setFilters}
                    />
                    <DateFilter
                      name="Created Before"
                      filterName="before"
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </>
                )}
              </FilterGroup>
            </FilterMenu>
            {/* 224px width from filter menu */}
            <div className="w-[calc(100dvw_-_224px)]">
              <div
                className={twMerge(
                  "overflow-scroll",
                  "w-full",
                  user?.internal
                    ? "max-h-[calc(100dvh_-_227px)]"
                    : "max-h-[calc(100dvh_-_179px)]",
                )}
              >
                <div className="h-1" />
                <table className="w-full min-w-max border-l border-r border-gray-200 text-sm">
                  <thead className="align-start">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className="shadow-[0_1px_0_0_rgba(217,217,217,1)] sticky top-0 z-50"
                      >
                        {headerGroup.headers.map((header) => (
                          <th
                            colSpan={header.colSpan}
                            className="p-3 text-start uppercase font-semibold text-xs truncate"
                            key={header.id}
                            ref={(th) =>
                              columnSizingHandler(th, table, header.column)
                            }
                            style={{
                              ...getScreenerPinningStyles(header.column),
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center gap-2"
                                    : ""
                                }
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                                {header.column.getIsSorted()
                                  ? {
                                      asc: (
                                        <div className="w-full flex items-center">
                                          <Image
                                            src="/assets/SortAscending.svg"
                                            width={12}
                                            height={12}
                                            alt="sort"
                                          />
                                        </div>
                                      ),
                                      desc: (
                                        <div className="w-full flex items-center">
                                          <Image
                                            src="/assets/SortDescending.svg"
                                            width={12}
                                            height={12}
                                            alt="sort"
                                          />
                                        </div>
                                      ),
                                    }[header.column.getIsSorted() as string]
                                  : header.column.getCanSort() && (
                                      <div className="w-full flex items-center">
                                        <Image
                                          src="/assets/sort.svg"
                                          width={12}
                                          height={12}
                                          alt="sort"
                                        />
                                      </div>
                                    )}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className={twMerge(
                          "border-b",
                          "border-[#D9D9D9]",
                          screenerHasCompletedSessions(row.original) &&
                            "hover:brightness-95",
                          screenerHasCompletedSessions(row.original) &&
                            "cursor-pointer",
                        )}
                        data-testid={row.original.id}
                        onClick={() => {
                          track("Screener Clicked", {
                            screener_id: row.original.id,
                          });
                          router.push(`/screener/${row.original.id}`);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="p-3 w-max text-sm"
                            style={{
                              ...getScreenerPinningStyles(cell.column, row),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full h-16 flex justify-center items-center">
                <Pagination
                  total={Math.ceil(rows.length / pagination.pageSize)}
                  onChange={(value) =>
                    setPagination({
                      pageSize: pagination.pageSize,
                      pageIndex: value - 1,
                    })
                  }
                  color="#784DD6"
                  value={pagination.pageIndex + 1}
                />
              </div>
            </div>
          </div>
        </div>
        {user?.internal && <ImpersonationBanner />}
      </div>
    )
  );
};
